import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(private httpClient: HttpClient) { }

  async authorize(userData:{}){
    return this.httpClient.post(environment.liveChatMiddleware + "/createConnection", userData).pipe(
      map(res => {
        let result: any = res;
        if (result) {
          // const data = jwtDecoder(result.token);
          sessionStorage.setItem('tokenData', result.token);
          return result;
        } else {
          return false;
        }
      }), catchError((error) => {
        // console.log('error is intercept')
        // console.error(error);
        return throwError(error);
      })
    )
  }
}
