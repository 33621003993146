import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MessageListComponent } from './components/message-list/message-list.component';
import { MessageItemComponent } from './components/message-item/message-item.component';
import { MessageFormComponent } from './components/message-form/message-form.component';
import { FormsModule } from '@angular/forms';
import { ResponseComponent } from './widgets/response/response.component';
import { LiveChatComponent } from './widgets/live-chat/live-chat.component';
import { ModalContentComponent } from './widgets/modal-content/modal-content.component';
import { ImageComponent } from './widgets/image/image.component';
import { UsabillaComponent } from './widgets/usabilla/usabilla.component';
import { ButtonComponent } from './widgets/button/button.component';
import { AccordionComponent } from './widgets/accordion/accordion.component';
import { ListComponent } from './widgets/list/list.component';
import { InfoComponent } from './widgets/info/info.component';
import { DescriptionComponent } from './widgets/description/description.component';
import { ImageDesignComponent } from './widgets/image-design/image-design.component';
import { CombinedComponent } from './widgets/combined-design/combinedDesign.component';
import { ChipComponent } from './widgets/chipdesign/chip.component';

@NgModule({
  declarations: [
    AppComponent,
    MessageListComponent,
    MessageItemComponent,
    MessageFormComponent,
    ResponseComponent,
    LiveChatComponent,
    ModalContentComponent,
    ImageComponent,
    UsabillaComponent,
    ButtonComponent,
    CombinedComponent,
    AccordionComponent,
    ChipComponent,
    ListComponent,
    InfoComponent,
    DescriptionComponent,
    ImageDesignComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}