import { Component, OnInit, Input } from '@angular/core';
import { SharedService, ModalComponentService } from '../../services';
import { ImageComponent } from '../../widgets/image/image.component'

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.css']
})
export class ModalContentComponent implements OnInit {
  @Input()
  object: any;

  show: boolean = true;
  clickable: string = "enabled";

  modalType: any;
  component: any = '';
  modalDestroy: boolean = false;

  constructor(private _sharedService: SharedService, private _modalService: ModalComponentService) { }

  ngOnInit(): void { }

  showDialog() {
    if (this.object.modalType !== undefined) {
      this.modalType = this.object.modalType;
      this.modalDestroy = this.object.modalDestroy;

      if (this.modalDestroy == true) {
        this.show = false;
      }

      if (this.modalType === 'Image') {
        this._sharedService.setThumbnailclickable(false);
        this.component = ImageComponent;
        this._sharedService.setResponseData(this.object.ImageUrl);
        this._modalService.createModal(this.component);
      }
    }
  }

}
