import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-design',
  templateUrl: './image-design.component.html',
  styleUrls: ['./image-design.component.css']
})
export class ImageDesignComponent implements OnInit {

  @Input() object: any;
  public richText: any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.object);
    this.richText = this.object.textresponse;
  }

}
