import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {

  @Input() object: any;
  public richText: any; 

  public showInfo: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.richText = this.object.textresponse;
  }

}
