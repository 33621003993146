import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetSocketService {

  constructor(private httpClient: HttpClient) { }

  async getSocketUrl() {
    return this.httpClient.post(environment.liveChatMiddleware + "/createParticipantConnection", null, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('tokenData')
      }
    }).pipe(
      map(res => {
        let result: any = res;
        if (result) {
          sessionStorage.setItem('socket', result.socketUrl);
          return result;
        } else {
          return false;
        }
      })
    )
  }

  async disconnectParticipent() {
    console.log("disconnect service");
    return this.httpClient.post(environment.liveChatMiddleware + "/disconnectParticipant", null, {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('tokenData')
      }
    }).pipe(
      map(res => {
        let result: any = res;
        if (result) {
          return result;
        } else {
          console.log("NO result in disconnect")
          return false;
        }
      }), catchError((error: any) => {
        console.log('error is intercept');
        console.log(error);
        return throwError(error);
      })
    );
  }
}
