<div class="panel panel-default">
    <div class="panel-body bodylistItem">
        <div class="imgLiv">
            <img src='{{richText.imageUrl}}' class="imgLivraison">
        </div>
        <div class="titleList">{{richText.title}}</div>
        <div>
            <ul *ngFor="let line of listText">
                <li class="listItem">{{line.text}}</li>
            </ul>
            <!-- <div class="link">
                <a class="livraisonLink" href='{{richText.linkUrl}}' target="_blank">{{richText.linkText}}</a>
            </div> -->
        </div>
    </div>
    <div class="panel-footer footerlistItem">
        <a href='{{richText.linkUrl}}' target="_bank" class="informationButton">{{richText.textUrl}}</a>
    </div>
</div>