<div id="chat-loading-busy" *ngIf='loading'>
    <div class="three-balls-busy">
        <div class="ball ball1"></div>
        <div class="ball ball2"></div>
        <div class="ball ball3"></div>
    </div>
</div>

<div class="chatbot-panel-footer-enabled" *ngIf='isformallowed && !isLiveChat'>
    <form name="form" (ngSubmit)="sendMessage()">
        <div class="chatbot-input-group" id="chat-form">
            <input id="btn-input" type="text" name="input-message" autocomplete="off"
                placeholder="{{ 'msgForm.footerLabel' | translate }}" [(ngModel)]="message.content" />
            <span class="chatbot-input-group-btn">
                <button type="submit" id="btn-chat-plane">
                    <i class="fa fa-paper-plane"></i>
                </button>
                <button type="submit" id="btn-chat-push">{{ 'msgForm.sendButtonLabel' | translate }}</button>
            </span>
        </div>
    </form>
</div>

<div class="chatbot-panel-footer-enabled" *ngIf='isLiveChat && !isformallowed'>
    <form name="form" (ngSubmit)="sendLiveChatMessage()">
        <div class="chatbot-input-group" id="chat-form">
            <input id="btn-input" type="text" name="input-message" autocomplete="off"
                placeholder="{{ 'msgForm.footerLabel' | translate }}" [(ngModel)]="message.content" />
            <span class="chatbot-input-group-btn">
                <button type="submit" id="btn-chat-plane">
                    <i class="fa fa-paper-plane"></i>
                </button>
                <button type="submit" id="btn-chat-push">{{ 'msgForm.sendButtonLabel' | translate }}</button>
            </span>
        </div>
    </form>
</div>

<div class="chatbot-panel-footer-disabled" *ngIf='!isformallowed && !isLiveChat'>
    <form name="form" (ngSubmit)="sendMessage()">
        <div class="chatbot-input-group" id="chat-form">
            <input disabled id="btn-input" type="text" name="input-message" autocomplete="off"
                placeholder="{{ 'msgForm.footerLabel' | translate }}" [(ngModel)]="message.content" />
            <span class="chatbot-input-group-btn">
                <button disabled type="submit" id="btn-chat-plane-disabled">
                    <i class="fa fa-paper-plane"></i>
                </button>
                <button disabled type="submit" id="btn-chat-push">{{ 'msgForm.sendButtonLabel' | translate }}</button>
            </span>
        </div>
    </form>
</div>