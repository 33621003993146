<div class="panel panel-primary chatbot-container" *ngIf='displayChatbot' id="chatbot-container">
    <div class="chatbot-panel-heading">
        <div id="close-chatbot" (click)="closeChatbot()">
            <span class="close-chat">&times;</span>
        </div>
        <span class="chatbot-panel-heading">{{ 'app.heading' | translate }}</span>
        <span class="chatbot-panel-subheading">{{ 'app.subHeading' | translate }}</span>
    </div>
    <message-list id="message-list" [messages]="messages"></message-list>
    <message-form [message]="message" [messages]="messages"></message-form>
    <div class="LiveChat-modal-content" *ngIf="isModalcontent" id="close-livechat">
        <div class="chat-modal-window-Warningcontent">
            <button class="modal-closeButton" type="button" (click)="closeModal()">&times;</button>
            <div class="warning" *ngIf="isWarning">
                <p>{{'app.warningText' | translate }}</p>
                <button class="modal-button" (click)="closeLivechat()">{{ 'app.buttonYes' | translate }}</button>
                <button class="modal-button" (click)="closeModal()">{{ 'app.buttonNo' | translate}}</button>
            </div>
            <app-usabilla *ngIf="isUsabillaforLiveChat"></app-usabilla>
        </div>
    </div>
</div>

<div class="container" *ngIf='displayChatbot'>
    <button id="show-chatbot" class="chat-cta" (click)="showChatbot()"></button>
    <button id="chat-ext" (click)="showChatbot()">{{ 'app.animationText' | translate }}<a (click)="showChatbot()"
      style="color:#EF2637ed">{{ 'app.clickHere' | translate }}</a></button>
</div>