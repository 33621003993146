<div class="panel panel-default">
  <div class="panel-body bodylistItem">
    <div class="titleAccordion">
      {{richText.title}}
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-8 subtitle">
          <span> {{richText.subTitle}} </span>
        </div>
        <div class="col-md-4 col-sm-4 buttonShow">
          <button class="arrowAccordion" data-toggle="collapse" data-target="#myDiv" (click)="showInfo = !showInfo">
            <img *ngIf="showInfo == false" src='../../../assets/images/chevron-down.gif' width='30' height='30' />
            <img *ngIf="showInfo" src='../../../assets/images/chevron-up.gif' width='30' height='30' />
          </button>
        </div>
      </div>
    </div>
    <div class="textInformation" id="myDIV" *ngIf="showInfo">
      {{richText.informationText}}
    </div>
  </div>
  <div class="panel-footer footerlistItem">
    <a class="informationButton" href='{{richText.linkUrl}}' target="_bank">{{richText.informationButton}}</a>
  </div>
</div>