
<table>
    <thead>
        <tr>
            <th>{{richText.title}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let line of listText">
            <td><a class="platformDesign" href='{{line.link}}' target="_blank">{{line.text}}</a></td>
        </tr>
    </tbody>
</table>