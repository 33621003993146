import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() object: any;
  public richText: any;
  public listText: any;
  constructor() { }

  ngOnInit(): void {
    this.richText = this.object.textresponse;
    this.listText = this.richText.list;
  }

}
