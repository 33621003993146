import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { Message } from '../../models/message'
import { ResponseComponent } from '../../widgets/response/response.component';
import { SharedService } from '../../services';
import { LiveChatComponent } from 'src/app/widgets/live-chat/live-chat.component';
import { ModalContentComponent } from '../../widgets/modal-content/modal-content.component';
import { UsabillaComponent } from '../../widgets/usabilla/usabilla.component';
import { ButtonComponent } from '../../widgets/button/button.component';
import { AccordionComponent } from '../../widgets/accordion/accordion.component';
import { ListComponent } from '../../widgets/list/list.component';
import { InfoComponent } from '../../widgets/info/info.component';
import { DescriptionComponent } from '../../widgets/description/description.component';
import { ImageDesignComponent } from '../../widgets/image-design/image-design.component';
import { CombinedComponent } from 'src/app/widgets/combined-design/combinedDesign.component';
import { ChipComponent } from 'src/app/widgets/chipdesign/chip.component';

@Component({
  selector: 'message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.css']
})
export class MessageItemComponent implements OnInit {

  @Input("message")
  public message: Message;

  @Input("widget")
  public widget: any;
  @Input("inputResponseObj")
  public inputResponseObj: any;
  component: any;
  componentRef: any;

  @ViewChild("dynamicResponse", { read: ViewContainerRef })
  vc: ViewContainerRef;

  public isRichText: boolean = false;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private _sharedService: SharedService, private cdRef: ChangeDetectorRef,) { }

  ngOnInit(): void {
    console.log(this.widget);
    if (this.widget == "options") {
      this.component = ResponseComponent;
    }
    if (this.widget == "livechat") {
      this.component = LiveChatComponent;
    }
    if (this.widget == "modal") {
      this.widget = ModalContentComponent;
    }
    if (this.widget == "EOC") {
      this.component = UsabillaComponent;
    }
    if (this.widget == "rich-design-accordion") {
      this.component = AccordionComponent;
      this.isRichText = true;
      this.message.showoption = false;
    }
    if (this.widget == "rich-design-button") {
      this.component = ButtonComponent;
      this.isRichText = true;
      this.message.showoption = false;
    }
    if (this.widget == "rich-design-information") {
      this.component = InfoComponent;
      this.isRichText = true;
      this.message.showoption = false;
    }
    if (this.widget == "rich-design-list") {
      this.component = ListComponent;
      this.isRichText = true;
      this.message.showoption = false;
    }
    if (this.widget == "rich-design-description") {
      this.component = DescriptionComponent;
      this.isRichText = true;
      this.message.showoption = false;
    }
    if (this.widget == "rich-design-image") {
      this.component = ImageDesignComponent;
      this.isRichText = true;
      this.message.showoption = false;
    }
    if (this.widget == "rich-design-combined") {
      this.component = CombinedComponent;
      this.isRichText = true;
      this.message.showoption = false;
    }
    if (this.widget == "rich-design-chip") {
      this.component = ChipComponent;
      this.isRichText = true;
      this.message.showoption = false;
    }
  }

  ngAfterViewInit() {
    if (this.component !== null && this.component !== undefined) {
      if (this.vc) {
        this.componentRef = this.vc.createComponent(
          this.componentFactoryResolver.resolveComponentFactory(this.component)
        );
      }
      if (this.componentRef !== undefined) {
        this.componentRef.instance.object = this.inputResponseObj;
        if (this.componentRef.instance.clicked) {
          this.componentRef.instance.clicked.subscribe((type: any) => {
            this._sharedService.sendData(type);
          });
        }
      }
    }
    this.cdRef.detectChanges();
  }
}
