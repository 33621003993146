import { Injectable, ApplicationRef, ComponentFactoryResolver,EmbeddedViewRef,Injector } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ModalComponentService {

  constructor(private appRef: ApplicationRef, private factoryResolver: ComponentFactoryResolver,private injector: Injector) { }

  createModal(component:any){
    const componentRef=this.factoryResolver.resolveComponentFactory(component).create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }
}
