import { Component, OnInit } from '@angular/core';
import { SharedService } from './../../services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
const usb = require('../../../assets/js/usabilla');

@Component({
  selector: 'app-usabilla',
  templateUrl: './usabilla.component.html',
  styleUrls: ['./usabilla.component.css']
})
export class UsabillaComponent implements OnInit {

  public language: any;

  constructor(private sharedService: SharedService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.language = this.sharedService.getLanguage();
    const ub: any = document.getElementById('ub');

    if (this.language === 'fr') {
      ub.setAttribute('ub-in-page', environment.usabillaKeyFR);
    }
    if (this.language === 'nl') {
      ub.setAttribute('ub-in-page', environment.usabillaKeyNL);
    }

    if(this.sharedService.isLiveChat()) {
      ub.setAttribute('ub-in-page-communicationtype', environment.usabillaCommTypeLivechat);
      // setting liveChat flag to false here instead of closeLivechat() of app.component.ts because of a sync issue
      // it was making liveChat flag false before coming to this check (ngOnInit() method of this component) 
      this.sharedService.setLiveChat(false);
      let usabillaWidget: HTMLElement | null = document.getElementById("ub")
      if(usabillaWidget){
        usabillaWidget.style.marginLeft = "0px";
      }
    } else {
      ub.setAttribute('ub-in-page-communicationtype', environment.usabillaCommTypeChatbot);
    }

    usb.usabilla_load();
    this.updateScroll();
  }

  updateScroll() {
    setTimeout(() => {
      const element: any = document.querySelector('#chat-scroll');
      if (element !== undefined || element !== null) {
        element.scrollTop = element.scrollHeight;
      }
    }, 1000);
  }
}
