import { Component, OnInit, Input } from '@angular/core';
import { Message } from '../../models/message';
import { SharedService, CreateConnectionService, ChatService, GetSocketService } from '../../services';
import { WebSocketSubject } from 'rxjs/webSocket';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'message-form',
	templateUrl: './message-form.component.html',
	styleUrls: ['./message-form.component.css']
})
export class MessageFormComponent implements OnInit {

	@Input('message')
	public message!: Message;
	@Input('messages')
	public messages!: Message[];
	public isformallowed: boolean = false;
	private _message!: string;
	public loading = false;
	public lang!: string;
	public conversationHistory: any = [];
	public param: string = window.location.href.split('?')[1];

	isLiveChat: boolean = false;
	agentName!: string;
	socketRetryCount!: number;
	showUnread!: number;
	interval: any;
	isAgentJoined: boolean = false;
	intervalStarted: boolean = false;

	constructor(private _sharedService: SharedService,
		private connectionService: CreateConnectionService,
		private chatService: ChatService,
		private getSocketService: GetSocketService,
		private translate: TranslateService) { }

	ngOnInit() {
		this.isformallowed = true;

		this.lang = this._sharedService.getLanguage();

		this._sharedService.getAgentJoinedInfo().subscribe(info => {
			if (info === true) {
				this.isLiveChat = true;
				this.isformallowed = false;
				this.receivedEvents();
			}
			else if (info === false) {
				this.isLiveChat = false;
				this.isformallowed = false;
			}
		});

		this._sharedService.getData().subscribe((message: any) => {
			this.message.content = message;
			this.sendMessage();
		});
	}

	public sendMessage(): void {
		this._message = this.message.content.trim();
		if (this._message != null && this._message != '') {
			this.loading = true;
			this.message.timestamp = new Date();
			this.messages.push(this.message);

			let userText = { "type": "userMessage", "message": this.message.content }
			this.conversationHistory.push(userText);

			this.chatService.getResponse(this.message.content, this.lang)?.subscribe(res => {
				if (res.textresponse !== null && res.textresponse !== undefined) {
					this.messages.push(new Message(res.textresponse, 'bpost', new Date(), true));

					let botText = { "type": "botText", "message": res.textresponse };
					this.conversationHistory.push(botText);

					this.isformallowed = res.isFormAllowed;
					if (res.widget !== null && res.widget !== undefined) {
						this._sharedService.updateView(res.widget);
						this._sharedService.setResponseData(res);
					}
					else {
						this._sharedService.updateView('');
					}
				} else {
					this.translate.get('msgForm.fallbackNote').subscribe((fallbackNote: string) => {
						this.messages.push(new Message(fallbackNote, 'bpost', new Date(), false));
					})
					this.isformallowed = true;
				}
				this.loading = false;
			});
			this.message = new Message('', 'user', this.message.timestamp, true);
		}

		localStorage.setItem("ConversationHistory", JSON.stringify(this.conversationHistory));
	}

	invokeIntervalMessage() {
		let counter = 0;
		this.interval = setInterval(() => {
			counter++;
			if (3 === counter) {
				this.translate.get('msgForm.finalMessage').subscribe((finalMessage: string) => {
					this.messages.push(new Message(finalMessage, 'agent', new Date(), false));
				});
				if (this.interval) {
					clearInterval(this.interval);
					this.closeLivechat();
				}
			}
			else {
				this.translate.get('msgForm.waitMessage').subscribe((waitMessage: string) => {
					this.messages.push(new Message(waitMessage, 'agent', new Date(), false));
				});
			}
		}, 90 * 1000); // execute in 90 seconds delay
	}

	receivedEvents() {

		this.translate.get('msgForm.customerJoinedTxt').subscribe((customerJoinedTxt: string) => {
			this.messages.push(new Message(customerJoinedTxt, 'agent', new Date(), false));
		});

		let socket: WebSocketSubject<any> = this.connectionService.getNewWebSocket();
		socket.subscribe((data: any) => {
			if (data === false) {
				this.getSocket();
			}
			else {
				this.socketRetryCount = 0;
				//this.errorMsg.message = '';
				let minimizeFlag = sessionStorage.getItem('minimize');

				let content: any;
				try {
					content = JSON.parse(JSON.stringify(data.content).replace(/\\/g, ''));
				} catch {
					content = JSON.parse(data.content);
				}
				console.log(content);

				//start timer
				if (!this.isAgentJoined && !this.intervalStarted && content.ParticipantRole !== 'SYSTEM' && content.Type !== 'EVENT') {
					this.intervalStarted = true;
					this.invokeIntervalMessage();
				}

				if (content.DisplayName == 'SYSTEM_MESSAGE') {
					this.agentName = "";
				} else {
					this.agentName = content.DisplayName;
				}

				if (content.Content !== null && content.Content !== undefined && (content.ParticipantRole == "AGENT" || content.ParticipantRole == "SYSTEM")) {
					console.log(content.Content);
					this.loading = false;

					this.messages.push(new Message(content.Content, 'bpost', new Date(), false));
					console.log(this.messages);

					if (minimizeFlag == 'false') {
						this.showUnread++;
					} else {
						this.showUnread = 0;
					}
				} else if (content.ContentType == "application/vnd.amazonaws.connect.event.typing") {
					this.loading = true;
					this.delaymethod(2000);
					this.loading = false;
				} else if (content.ContentType == "application/vnd.amazonaws.connect.event.participant.joined") {
					if (content.ParticipantRole !== 'CUSTOMER') {
						this.isAgentJoined = true;
						if (this.interval) {
							clearInterval(this.interval);
							this.intervalStarted = false;
						}
						this.loading = false;
						this.translate.get('msgForm.agentJoinedTxt').subscribe((agentJoinedTxt: string) => {
							this.messages.push(new Message(content.DisplayName + agentJoinedTxt, 'agent', new Date(), false));
						});
					}
				} else if (content.ContentType == "application/vnd.amazonaws.connect.event.participant.left") {
					console.log('agent left')
					if (content.ParticipantRole !== 'CUSTOMER') {
						this.isAgentJoined = false;
						this.loading = false;
						this.translate.get('msgForm.agentLeftTxt').subscribe((agentLeftTxt: string) => {
							this.messages.push(new Message(content.DisplayName + agentLeftTxt, 'agent', new Date(), false));
						});
					}
				}
				else if (content.ContentType == "application/vnd.amazonaws.connect.event.chat.ended") {
					console.log('Chat ended')
					localStorage.setItem('isLiveChat', 'false');
					this._sharedService.setAgentInfo(false);
				}
			}
		}, async (error: any) => {
			console.log('error', error);
			if (this.socketRetryCount >= 10) {
				this.translate.get('msgForm.errorMsg').subscribe((errorMsg: string) => {
					this.message = new Message(errorMsg, 'Bpost', new Date(), false);
				});
			} else {
				this.socketRetryCount++;
				await this.getSocket();
				this.connectionService.sendMessage({ "topic": "aws/subscribe", "content": { "topics": ["aws/chat"] } });
			}
		});
	}

	public async sendLiveChatMessage() {

		this._message = this.message.content.trim();
		if (this._message != null && this._message != '') {
			this.loading = true;
			this.message.timestamp = new Date();
			this.messages.push(this.message);
		}

		this.loading = false;
		let message = {
			content: this.message.content,
			contentType: "text/plain"
		};
		(await this.connectionService.sendUserMessage(message)).subscribe((data: any) => {
			if (data === false) { console.log(data); }
			else {
				console.log(data);
			};
		}, (error: any) => {
			if (error.status !== 200) {
				this.translate.get('msgForm.errorMsg').subscribe((errorMsg: string) => {
					this.message = new Message(errorMsg, 'Bpost', new Date(), false);
				});
			}
		});
		this.message = new Message('', 'user', this.message.timestamp, true);
	}

	async getSocket() {
		(await this.getSocketService.getSocketUrl()).subscribe((data: any) => {
			if (data === false) { }
			else {
				sessionStorage.setItem('socketUrl', data.socketUrl);
				console.log(data);
				this.receivedEvents();
				this.connectionService.sendMessage({ "topic": "aws/subscribe", "content": { "topics": ["aws/chat"] } });
			};
		}, (error: any) => {
			console.log('Failed to connect through socket');
		})
	}

	public delaymethod(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	async closeLivechat() {
		localStorage.setItem('isLiveChat', 'false');
		this._sharedService.setAgentInfo(false);
		await this.closeConnection();
		console.log("disconnect");
	}

	async closeConnection() {
		(await (this.getSocketService.disconnectParticipent())).subscribe(
			(data: any) => { if (data === false) { return; } else { return; }; },
			(error: any) => { // console.log('error', error);
			});
	}
}
