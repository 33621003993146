<div class="panel panel-default">
    <div class="panel-body bodylistItem">
        <div class="imgLiv">
            <img src='{{richText.imageUrl}}' class="imgLivraison">
        </div>
        <div class="titleList">{{richText.title}}</div>
        <div class="listItem">{{richText.informationText}}  
        </div>
    </div>
    <a href='{{richText.linkUrl}}' target="_bank">
    <div class="panel-footer footerlistItem">
        
        <button class="informationButton">{{richText.textUrl}}</button>
       
    </div>
    </a>
    <div *ngIf='!destroy' class="btn-choice">
        <li class="response-options" *ngFor='let option of options'>
            <button class="questionOpen" id='btn-option' name='{{option.text}}' value="{{option.link}}" (click)='selectOption($event)'>{{option.text}}</button>
        </li>
    </div>
</div>