import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {

  response: any;
  show: boolean = true;

  constructor(private _sharedService: SharedService) { 
    this._sharedService.responseObjectService.subscribe(res=>{
      this.response=res
    });
  }

  close(){
    this._sharedService.setThumbnailclickable(true);
    this.show=false;
  }

  ngOnInit(): void {
  }

}
