import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  data: any;
  public dataService = new Subject<any>();
  public viewService = new Subject<any>();
  public responseObjectService= new BehaviorSubject<any>("");
  public thumbnailClickable=new BehaviorSubject<any>(true);
  public messageService = new Subject<any>();
  public AgentJoinedInfo = new Subject<any>();
  private liveChat = false;
  public sessionId: any;
  private language: string;

  constructor() { }

  sendData(data: any) {
    this.dataService.next(data);
  }
  getData(): Observable<any> {
    return this.dataService.asObservable();
  }
  updateView(widgetInfo: any) {
    this.viewService.next(widgetInfo);
  }
  setResponseData(data:any){
    this.responseObjectService.next(data);
  }
  getView(): Observable<any> {
    return this.viewService.asObservable();
  }
  setThumbnailclickable(data:any){
    this.thumbnailClickable.next(data);
  }
  setMessage(Message: any) {
    this.messageService.next(Message);
  }
  setAgentInfo(data:any) {
    this.AgentJoinedInfo.next(data);
  }
  getAgentJoinedInfo(): Observable<any> {
    return this.AgentJoinedInfo.asObservable();
  }
  setLiveChat(liveChat: boolean) {
    this.liveChat = liveChat;
  }
  isLiveChat(): boolean {
    return this.liveChat;
  }
  getSessionId() {
    return this.sessionId;
  }
  setLanguage(language: string) {
    this.language = language;
  }
  getLanguage(): string {
    return this.language;
  }
}
