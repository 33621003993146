export class Message {
    content: string;
    timestamp?: Date;
    user: string;
    showoption?: boolean;

    constructor(content: string, user: string, timestamp?: Date, showoption?: boolean) {
        this.content = content;
        this.user = user;
        this.timestamp = timestamp;
        this.showoption = showoption;
    }
}
