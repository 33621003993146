
<div class="panel panel-default">
  <div class="panel-body">
    <p> {{chipQuestion}} </p>
  </div>
</div>
<div *ngIf='!destroy' class="btn-choice">
   <li class="response-options" *ngFor='let option of options'>
       <button class="questionOpen" id='btn-option' name='{{option.text}}'><a href='{{option.link}}' alt='Broken' target="_blank">{{option.text}}</a></button>
   </li>
</div>