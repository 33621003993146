import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-combined',
  templateUrl: './combinedDesign.component.html',
  styleUrls: ['./combinedDesign.component.css']
})
export class CombinedComponent implements OnInit {

  @Input() object: any;
  public richText: any;
  options: any;
  destroy: boolean = false;
  @Output() clicked = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit(): void {
    this.richText = this.object.textresponse;
    this.options = this.richText.options;

    console.log(this.richText);
    console.log(this.options);
  }

  
  selectOption(event:any) {
    var data = {key:event.target.name,value:event.target.value}
    console.log(event.target.innerText);
  
    this.clicked.emit(event.target.innerText);
    this.destroy=true;
  }

}
