import { Component, AfterViewChecked, Input, ElementRef } from '@angular/core';
import { Message } from '../../models/message';
import { SharedService } from '../../services';

@Component({
  selector: 'message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css']
})
export class MessageListComponent implements AfterViewChecked {

  @Input('messages')
  public messages: Message[]
  emitResponseObj: any;
  response: any;

  constructor(private el: ElementRef, private _sharedService: SharedService) {
    this._sharedService.getView().subscribe(res => {
      this.response = res;
    });
    this._sharedService.responseObjectService.subscribe(res => {
      console.log("messageList res", res);
      this.emitResponseObj = res;

      try {
        if (this.emitResponseObj.textresponse.includes("{")) {
          this.emitResponseObj.textresponse = JSON.parse(this.emitResponseObj.textresponse);
          console.log(this.emitResponseObj.textresponse);
        }
      } catch (err) {
        console.log("failed", err);
      }
      
      console.log(this.messages);
    });
  }

  ngAfterViewChecked() {
    this.updateScroll();
  }

  updateScroll() {
    var element = this.el.nativeElement.querySelector('#chat-scroll');
    if (element != undefined || element != null) {
      element.scrollTop = element.scrollHeight;
    }
  }

}
