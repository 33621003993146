import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment'


@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.css']
})
export class ChipComponent implements OnInit {

  @Input() object: any;
  destroy: boolean = false;
  options: any;
  chipQuestion: any;
  
  @Output() clicked = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    console.log(this.object);
    this.options = this.object.textresponse.options;
    this.chipQuestion = this.object.textresponse.text; 
    console.log(this.options);
  }

  selectOption(event:any) {
    var data = {key:event.target.name,value:event.target.value}
    
    this.clicked.emit(event.target.innerText);
    this.destroy=true;
  }

}
