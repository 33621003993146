import { Component, HostListener } from '@angular/core';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Message } from './models/message';
import { GetSocketService, SharedService } from './services';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private baseURL: string = environment.chatbotBackend;
  public message: Message;
  public subs: Subscription;
  public messages: Message[];
  public displayChatbot: boolean = true;
  public lang: string;
  public param: string = window.location.href.split('?')[1];
  public isModalcontent: boolean = false;
  public isWarning: boolean = false;
  public isUsabillaforLiveChat: boolean = false;
  private platform: string;

  constructor(private http: HttpClient, private getSocket: GetSocketService,
    private translate: TranslateService, private sharedService: SharedService,
    private deviceService?: DeviceDetectorService) {

    if (this.param) {
      this.param = decodeURI(this.param).replace(/\s/g, '');
      if (this.param.split('lang=')[1]) {
        this.lang = this.param.split('lang=')[1];
      }
    }

    this.translate.setDefaultLang(this.lang ? this.lang : environment.language);
    this.sharedService.setLanguage(this.lang ? this.lang : environment.language);

    this.message = new Message('', 'user');
    translate.get('app.welcomeNote').subscribe((welcomeNote: string) => {
      this.messages = [
        new Message(welcomeNote, 'welcome', new Date())
      ];
    });

    this.autoAnimationDisplay();
    localStorage.setItem('isLiveChat', 'false');
    this.sharedService.setLiveChat(false);
  }

  // public getHeaders() {
  //   let headers = new Headers();
  //   headers.append("platform", "bpost-web");
  //   headers.append("content-type", "application/json;charset=UTF-8");
  //   return headers;
  // }

  public getTestResponse() {
    let data = {
      query: "Health check",
      lang: environment.language,
      sessionId: "test"
    };
    // return this.http.post(`${this.baseURL}`, data, { headers: this.getHeaders() }).pipe(
    //   map(res => {
    //     return res.json();
    //   })
    // );

    if (this.deviceService?.isMobile()) {
      this.platform = environment.platform + "-mob";
    } else {
      this.platform = environment.platform + "-web";
    }

    const headers = { 'platform': this.platform, 'content-type': 'application/json;charset=UTF-8' };
    return this.http.post<any>(`${this.baseURL}`, data, { headers }).subscribe(data => {
      return data;
    });
  }

  showChatbot() {
    let chatbotContainer: any = document.getElementById("chatbot-container");
    chatbotContainer.style.bottom = "0%";
    chatbotContainer.style.transition = "all linear 0.5s";
    setTimeout(function () {
      let chatLoading: any = document.getElementById("chat-loading");
      chatLoading.style.display = 'none';
      let chatWelcome: any = document.getElementById("chat-welcome");
      chatWelcome.style.display = "block";
    }, 2000);
  }

  closeChatbot() {
    if (this.sharedService.isLiveChat()) {
      this.isModalcontent = true;
      this.isWarning = true;
    } else {
      let chatbotContainer: any = document.getElementById("chatbot-container");
      if (chatbotContainer) {
        chatbotContainer.style.bottom = "-100%";
        chatbotContainer.style.transition = "all linear 0.5s";
      }

      let closeLivechat: any = document.getElementById("close-livechat");
      if(closeLivechat){
        closeLivechat.style.bottom = "-100%";
        closeLivechat.style.transition = "all linear 0.5s";
      }
    }
  }

  closeModal() {
    this.isModalcontent = false;
  }

  async closeLivechat() {
    localStorage.setItem('isLiveChat', 'false');
    this.sharedService.setAgentInfo(false);
    this.isWarning = false;
    this.isUsabillaforLiveChat = true;
    await this.closeConnection();
    console.log("disconnect");
  }

  async closeConnection() {
    (await (this.getSocket.disconnectParticipent())).subscribe(
      (data: any) => {
        if (data === false) {
          return;
        }
      },
      (error: any) => { // console.log('error', error);
      });
  }

  autoAnimationDisplay() {
    setTimeout(function () {
      let chatExt: any = document.getElementById("chat-ext");
      chatExt.style.display = "block";
    }, 2000);
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event : any) {
    if(localStorage.getItem('isLiveChat') == 'true'){
      this.closeLivechat();
      event.preventDefault();
      event.returnValue = false;
    }
  }

}
