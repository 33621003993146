import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, switchAll, map } from 'rxjs/operators';
import { EMPTY, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateConnectionService {
  private socket$: WebSocketSubject<any>;

  constructor(private httpClient: HttpClient) { }

  public getNewWebSocket() {
    let tmp: string | null = sessionStorage.getItem('socket');
    if(tmp){
      this.socket$ = webSocket(tmp);
    }
    return this.socket$    
  }


  sendMessage(msg: any){
    this.socket$.next(msg)
  }

  /**
   * send user message
   * 
   * @param {*} data
   * @returns
   * @memberof CreateConnectionService
   */
   async sendUserMessage(data : any) {
    // data['ptoken'] = jwtDecoder(sessionStorage.getItem('tokenData')).ptoken;
    return this.httpClient.post(environment.liveChatMiddleware + "/sendMessage", data, {
      headers: {
        'Authorization': "Bearer "+sessionStorage.getItem('tokenData')
      }
    }).pipe(
      map(res => {
        let result: any = res;
        if (result) {
          // console.log(result)
          // const data = jwtDecoder(result.token);
          return result;
        } else {
          return false;
        }
      }), catchError((error) => {
        // console.log('error is intercept')
        // console.error(error);
        return throwError(error);
      })
    );
  }
}
