import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})
export class DescriptionComponent implements OnInit {

  @Input() object: any;
  public richText: any;
  public listText: any; 

  constructor() { }

  ngOnInit(): void {
    console.log(this.object);
    this.richText = this.object.textresponse;
    this.listText = this.richText.list;
  }

}
