import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { UUID } from 'angular2-uuid';
import { HttpClient } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { DeviceDetectorService } from 'ngx-device-detector';
import { catchError } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private baseURL: string = environment.chatbotBackend;
  private uuid: any;
  private platform: string;

  constructor(private http: HttpClient, protected localStorage?: LocalStorage, private deviceService?: DeviceDetectorService) { }

  public getResponse(query: string, lang: string) {
    this.uuid = localStorage.getItem('cb-ses-id');
    if (this.uuid == null) {
      let id = UUID.UUID();
      this.uuid = id;
      localStorage.setItem('cb-ses-id', id);
    }

    if (this.deviceService?.isMobile()) {
      this.platform = environment.platform + "-mob";
    } else {
      this.platform = environment.platform + "-web";
    }

    let data = {
      query: query,
      lang: lang,
      sessionId: localStorage.getItem('cb-ses-id')
    }
    const headers = { 'platform': this.platform, 'content-type': 'application/json;charset=UTF-8' };
    return this.http.post<any>(`${this.baseURL}`, data, { headers }).pipe(
      catchError(this.handleError)
    );
  }

  public getHeaders() {
    let headers = new Headers();
    headers.append("content-type", "application/json;charset=UTF-8");
    return headers;
  }

  public handleError = (error: Response) => {
    return observableOf({ "type": "ERROR", "description": "Backend not available" });;
  }

}
