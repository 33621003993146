
<div class="panel panel-default">
    <div class="panel-body panelInfo">
        <a class="infoLink"  href='{{richText.moreInfo.link}}' target="_blank">
        <div class="row">
            <div>
                <span class="panel-img"> 
                  <img src='{{richText.imageUrl}}' class="imgPanel">
                </span>
            </div>
            <div class="panel-text">
                <div class="title">{{richText.title}}</div>
                <div class="subtitle">{{richText.subTitle}}</div>
            </div>
        </div>
        </a>
    </div>
</div>