import { Component, OnInit, EventEmitter, Output, AfterContentInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SharedService, AuthorizationService, GetSocketService, CreateConnectionService } from '../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.css']
})
export class LiveChatComponent implements OnInit, AfterContentInit {

  public customerName: String;
  public email: String;
  livechatWorking = true;
  showLayer2 = false;
  destroy = false;
  lang: any;
  @Output() onSuccess: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private getSocketConn: GetSocketService,
    private connectionService: CreateConnectionService,
    private translate: TranslateService
  ) { }

  ngAfterContentInit() { }

  ngOnInit() {
    this.lang = this.sharedService.getLanguage();
  }

  async initiateChat() {
    this.sharedService.setMessage(true);
    this.destroy = true;
    const data = {
      fullName: this.customerName,
      language: this.lang,
      appName: environment.appName,
      email: this.email,
      conversationHistory: localStorage.getItem('ConversationHistory')
    };
    (await this.authService.authorize(data)).subscribe((data: any) => {
      if (data === false) {
        this.onSuccess.emit('failed');
      }
      else {
        this.createConnection();
      };
    }, (error: any) => {
      this.onSuccess.emit('failed');
    });
  }

  async createConnection() {
    (await this.getSocketConn.getSocketUrl()).subscribe((data: any) => {
      if (data === false) {
        this.onSuccess.emit('failed');
      } else {
        sessionStorage.setItem('socketUrl', data.socketUrl);
        this.onSuccess.emit('success');
        this.sharedService.setAgentInfo(true);
        this.sharedService.setLiveChat(true);
        localStorage.setItem('isLiveChat', 'true');
        this.connectionService.sendMessage({ "topic": "aws/subscribe", "content": { "topics": ["aws/chat"] } });
      }
    }, (error: any) => {
      this.onSuccess.emit('failed');
    });
  }

  showLayer() {
    this.showLayer2 = true;
  }

  close() {
    this.showLayer2 = false;
  }

}
