// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    chatbotBackend: "https://api-bcb-dv2.bpost.cloud/bpaid/chatservice",
    liveChatMiddleware:  "https://ixr4vpqz85.execute-api.eu-central-1.amazonaws.com/np/livechat",
    production: false,  
    language: "fr", // default language for the app  
    appName: "BPAID", // app Name to send to Middleware
    platform: "bpaid",
    usabillaKeyFR: '6167106993126238fb4d08da',
    usabillaKeyNL: '61670f68d499ed40e32d9b1a',
    usabillaCommTypeLivechat: "livechat",
    usabillaCommTypeChatbot: "chatbot"
  };
  