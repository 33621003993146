<li class="left clearfix" *ngIf="message.user=='welcome'">
    <div id="chat-welcome">
        <span class="chat-img chatbot-pull-left">
            <img src='https://www.bpost.be/sites/default/files/custom/img/chatbot/Avatar_chat.png' alt="bpost"
                class="bpost-avatar">
        </span>
        <div class="chat-body clearfix">
            <p id="bpost" [innerHTML]="message.content"></p>
            <span class="time-chat-right">{{message.timestamp | date : 'HH:mm' }}</span>
            <span id="time" style="display:none">{{message.timestamp | date : 'HH:mm:ss' }}</span>
        </div>
    </div>
    <div id="chat-loading">
        <div class="three-balls">
            <div class="ball ball1"></div>
            <div class="ball ball2"></div>
            <div class="ball ball3"></div>
        </div>
    </div>
</li>


<li class="left clearfix" *ngIf="message.user=='bpost'">
    <span class="chat-img chatbot-pull-left">
        <img src='https://www.bpost.be/sites/default/files/custom/img/chatbot/Avatar_chat.png' alt="bpost"
            class="bpost-avatar">
    </span>

    <!-- <p id="bpost" [innerHTML]="message.content"></p> -->
    <div class="chat-body clearfix">
        <p id="bpost" [innerHTML]="message.content" *ngIf="isRichText == false"></p>
        <ng-container #dynamicResponse *ngIf="isRichText == true"></ng-container>
    </div>
    <div #dynamicResponse *ngIf="message.showoption"></div>
    <span class="time-chat-right">{{message.timestamp | date : 'HH:mm' }}</span>
    <span id="time" style="display:none">{{message.timestamp | date : 'HH:mm:ss' }}</span>

</li>


<li class="right clearfix" *ngIf="message.user=='user'">
    <div class="chat-body clearfix">
        <p id="user">{{message.content}}</p>
        <span class="time-chat-left">{{message.timestamp | date : 'HH:mm' }}</span>
        <span id="time" style="display:none">{{message.timestamp | date : 'HH:mm:ss' }}</span>
    </div>
</li>