<div *ngIf='!destroy'>
    <div class="chatbot-card">
        <form class="chatbot-form-signin">
            <div>
                <label for="name">{{ 'liveChat.userName' | translate }}</label>
                <label for="name" class="mandatory-field">*</label>
                <input [(ngModel)]="customerName" #nameField="ngModel" name="customerName" id="name" name="true" required type="name">
                <div class="chatbot-form-error" *ngIf="nameField.touched && !nameField.valid">
                    <div *ngIf="nameField.errors?.required">
                        {{ 'liveChat.userNameError' | translate }}
                    </div>
                    <div *ngIf="nameField.errors?.email && !nameField.errors?.required">
                        {{ 'liveChat.userNameError' | translate }}
                    </div>
                </div>
            </div>

            <div>
                <label for="email">{{ 'liveChat.emailAddress' | translate }}</label>
                <label for="email" class="mandatory-field">*</label>
                <input [(ngModel)]="email" #emailField="ngModel" name="email" id="email" email="true" required type="email">
                <div class="chatbot-form-error" *ngIf="emailField.touched && !emailField.valid">
                    <div *ngIf="emailField.errors?.required">
                        {{ 'liveChat.emailError1' | translate }}
                    </div>
                    <div *ngIf="emailField.errors?.email && !emailField.errors?.required">
                        {{ 'liveChat.emailError2' | translate }}
                    </div>
                </div>
            </div>

            <div class="privacy-block" *ngIf='!showLayer2'>
                <span id="privacy-link" [innerHTML]="'liveChat.privacyLayer1' | translate"></span>
                <a href="javascript:void(0)" (click)="showLayer()">{{ 'liveChat.clickHere' | translate }}</a>
            </div>

            <div class="chat-modal-content" *ngIf='showLayer2' (click)="close()">
                <div class="chat-modal-window-content">
                    <button type="button" (click)="close()">&times;</button>
                    <div id="chat-modal-privacy" [innerHTML]="'liveChat.privacyLayer2' | translate"></div>
                </div>
            </div>
            <button class="chatbot-btn-signin" [disabled]="!emailField.valid || !nameField.valid" (click)="initiateChat()">{{ 'liveChat.start_chat' | translate }}</button>

            <div class="chatbot-form-error" *ngIf="!livechatWorking">
                {{ 'liveChat.livechatTechnicalProblem' | translate }}
            </div>
        </form>
    </div>
</div>